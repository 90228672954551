































































import { Component, Prop, Ref } from 'vue-property-decorator'
import PhotogrpaherDialog from './PhotographerJobDialog.vue'
import LabUploadStepper from './labUpload/LabUploadStepper.vue'
import { Booking, JobStatus, BookingPhotographerStatus, StudioJob } from '@ht-lib/accounts-models'
import { BookingsMap, clearDataProvider, labUploadDataProvider, SimpleBooking, toSimpleBooking } from '../bookings'
import { JobSnap } from '@ht-lib/accounts-common'
import { filterFiles } from 'packages/image-util/lib'
import Vue from '../VueBase'
import { getJobMetaFile } from '../models/UploadJob'

const visibleStatuses = [JobStatus.DRAFT, JobStatus.WAITING_FOR_PHOTOGRAPHERS, JobStatus.AWAITING_APPROVAL]

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { PhotogrpaherDialog, LabUploadStepper },
  name: 'PhotographerBookingList'
})
export default class extends Vue {
  @Ref() selectJobInput!: HTMLInputElement
  @Prop({ required: true }) bookingMap!: BookingsMap
  @Prop({ required: false, default: false }) isLabUploader: boolean

  activeBookingId: string | null = null
  activeStudioJob: StudioJob | null = null

  created (): void {
    clearDataProvider()
  }

  get bookings (): SimpleBooking[] {
    return Object.values(this.bookingMap)
      .map(x => toSimpleBooking(x)) // TODO: Write a proper Booking model
  }

  get hasBookings (): boolean {
    return this.bookings.length > 0
  }

  get activeBooking (): SimpleBooking | null {
    if (this.activeBookingId === null) { return null }
    return toSimpleBooking(this.bookingMap[this.activeBookingId])
  }

  get activeJobs (): JobSnap[] | null {
    if (this.activeBookingId === null) { return null }
    return this.onlyVisibleJobs(this.activeBookingId)
  }

  numJobs (bookingId: string): number {
    return this.onlyVisibleJobs(bookingId)?.length ?? 0
  }

  onlyVisibleJobs (bookingId: string): JobSnap[] {
    return this.bookingMap[bookingId].jobs
      .filter(x => visibleStatuses.includes(x.data().status))
  }

  setActiveBooking (bookingId: string | null): void {
    console.log('setActiveBooking', bookingId)
    this.activeBookingId = bookingId
  }

  rowClass (booking: Booking): string {
    const status = booking.photographersMap[this.$auth.user.uid]?.status || ''
    return status === BookingPhotographerStatus.COMPLETE ? 'bg-green-11' : ''
  }

  private selectFileError = ''

  selectJobButtonClicked (): void {
    this.selectJobInput.value = ''
    this.selectJobInput.click()
  }

  async inputFiles (fileList: FileList): Promise<void> {
    clearDataProvider()

    this.selectFileError = ''
    const files = filterFiles(Array.from(fileList))
    const result = await getJobMetaFile(files)
    if (result instanceof Error) {
      this.selectFileError = result.message
    } else {
      labUploadDataProvider.setFiles(fileList)
      this.activeStudioJob = result.studio
    }
  }

  // example booking id: "BPOdVMTghxfYMwia4pgS",
  async navigateToUpload (bookingId: string, jobId?: string): Promise<void> {
    console.log('navigating', bookingId)
    await this.$router.push({
      name: 'uploadToBooking',
      params: { bookingId, jobId }
    })
  }
}
