





















import Vue from 'vue'
interface Props{
  accounts: number
  bookings: number
  isLabUploader: boolean
  labUploads: boolean
}
export default Vue.extend<Props>({
  props: {
    accounts: { required: true },
    bookings: { required: true },
    isLabUploader: { required: true },
    labUploads: { required: true }
  },
  functional: false
})
