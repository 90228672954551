







































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BookingSnap, updateBooking, JobSnap } from '@ht-lib/accounts-common'
import AccountJobDialog from './AccountJobDialog.vue'
import { Booking, BookingStatus } from '@ht-lib/accounts-models'
import { BookingsMap } from '../bookings'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { AccountJobDialog },
  name: 'AccountBookingList'
})
export default class extends Vue {
  @Prop({ required: true, type: Object }) bookingMap!: BookingsMap
  activeBookingId: string | null = null
  bookingCompleteDialogShown = false

  hideJob (): void {
    this.activeBookingId = null
  }

  showBookingCompleteDialog (bookingId: string): void {
    this.activeBookingId = bookingId
    this.bookingCompleteDialogShown = true
  }

  closeBookingCompleteDialog (): void {
    this.bookingCompleteDialogShown = false
    this.activeBookingId = null
  }

  async bookingIsComplete (): Promise<void> {
    if (this.activeBookingId) {
      await updateBooking(this.activeBookingId, {
        status: BookingStatus.COMPLETED
      })
    }
    this.closeBookingCompleteDialog()
  }

  get bookings (): BookingSnap[] {
    return Object.values(this.bookingMap)
      .map(x => x.booking)
  }

  jobs (bookingId: string): JobSnap[] {
    return this.bookingMap[bookingId].jobs
  }

  numJobs (bookingId: string): number {
    return this.jobs(bookingId)?.length ?? 0
  }

  getBookData (bookingId: string): Booking {
    return this.getBook(bookingId).data()
  }

  getBook (bookingId: string): BookingSnap {
    return this.bookings.find(x => x.id === bookingId)
  }

  showJobs (bookingId: string): void {
    this.activeBookingId = bookingId
  }
}
