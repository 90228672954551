import { render, staticRenderFns } from "./BookingsTabs.vue?vue&type=template&id=198e8399&lang=pug&"
import script from "./BookingsTabs.vue?vue&type=script&lang=ts&"
export * from "./BookingsTabs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QRouteTab from 'quasar/src/components/tabs/QRouteTab.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import qInstall from "../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QRouteTab,QBadge});
