















import Vue from 'vue'
import Component from 'vue-class-component'
import { allJobTypes, getJobTypeFromCode } from '@ht-lib/accounts-common'
import { SelectorOptions } from '../../models/common'
import { Prop } from 'vue-property-decorator'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { },
  name: 'SelectJobType'
})
export default class extends Vue {
  @Prop({ type: String, required: true }) bookingJobType!: string
  value = ''
  allJobTypes: SelectorOptions[] = []

  get options (): SelectorOptions[] {
    return this.allJobTypes
  }

  async created (): Promise<void> {
    const aimsData = await getJobTypeFromCode(this.bookingJobType)
    const jobTypes = await allJobTypes()
    this.allJobTypes = jobTypes
      .filter(x => x.market === aimsData?.market ?? 'S')
      .map(x => ({ value: x.id, label: x.description }))
    this.$emit('loaded')
  }

  jobTypeSelected (option: SelectorOptions): void {
    this.$emit('input', option.value)
  }
}
