import { CreateImageResponse, StudioImageWithClass } from '@ht-lib/accounts-models'
import { isExifRotation, resizeFile } from '@ht-web/canvas-image'

export function tryCreateUploadImage (files: File[], image: StudioImageWithClass): CreateImageResponse {
  const file = files.find(f => f.name === `${ image.ref }.jpg`)
  if (file === undefined) {
    return { error: `Image with REF: ${ image.ref } was listed in data but was not found with the files that were uploaded. Image will NOT be imported` }
  }

  if (!image.longRef) {
    return { error: `Image with REF: ${ image.ref } does not have a longRef. Image will NOT be imported` }
  }

  const exif = image.exifOrientation
  if (!isExifRotation(exif)) {
    console.error(`No EXIF Orientation value for image with REF: ${ image.ref }. Image WILL be imported`)
  }

  let blob: Blob
  const getBlob = async () => {
    if (!blob) {
      blob = await resizeFile({ image: file })
    }

    return blob
  }

  return {
    image: {
      file,
      image,
      getBlob
    }
  }
}
