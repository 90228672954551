














import { Component, Vue, Prop } from 'vue-property-decorator'
import { JobSnap } from '@ht-lib/accounts-common'
import { StackedText } from '@ht-vue/misc'

interface Row {
  name: string
  imageCount: number
  uploadedJobs: number
}

@Component({
  name: 'PhotographerBreakdown',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { StackedText }
})
export default class extends Vue {
  @Prop({ required: true }) jobs!: JobSnap[]

  get graphers (): Row[] {
    const outMap: {[id: string]: Row} = {}
    this.jobs.forEach(
      job => {
        const pMap = job.data().photographersMap
        for (const photographerId in pMap) {
          const photo = pMap[photographerId]
          const c = outMap[photographerId]

          const uploadJobIncr = photo.imageCount ? 1 : 0
          if (!c) {
            outMap[photographerId] = {
              name: photo.name,
              imageCount: photo.imageCount,
              uploadedJobs: uploadJobIncr
            }
            continue
          }

          c.uploadedJobs += uploadJobIncr
          c.imageCount += photo.imageCount
        }
      }
    )

    return Object.values(outMap)
  }
}
