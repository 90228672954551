






















































import { Booking, BookingPhotographer, Job, StudioJob, WithId } from '@ht-lib/accounts-models'
import { getBooking } from '@ht-lib/accounts-common'
import QStepper from 'quasar/src/components/stepper/QStepper.js';
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Ref } from 'vue-property-decorator'
import BookingSearch from './BookingSearch.vue'
import SelectPhotographer from './SelectPhotographer.vue'
import SelectJobType from './SelectJobType.vue'
import SelectJob from './SelectJob.vue'
import { labUploadDataProvider } from '../../bookings'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { BookingSearch, SelectJobType, SelectPhotographer, SelectJob },
  name: 'LabUploadStepper'
})
export default class extends Vue {
  @Ref() uploadStepper!: QStepper
  @Prop({ type: Object, required: true }) studioJob!: StudioJob

  loading = true
  step = 0
  booking: WithId<Booking> | null = null
  photographer?: BookingPhotographer
  jobType = ''
  job: WithId<Job> | null

  get accountCode (): string {
    return this.studioJob.code
  }

  get photographers (): BookingPhotographer[] {
    return Object.values(this.booking.photographersMap)
  }

  get bookingJobType (): string {
    return this.booking?.jobType ?? ''
  }

  get bookingId (): string {
    return this.booking?.id ?? ''
  }

  async mounted (): Promise<void> {
    if (this.studioJob.job.bookingId?.length > 0) {
      await this.setupBooking(this.studioJob.job.bookingId)
    } else {
      this.loading = false
    }
  }

  async setupBooking (bookingId: string): Promise<void> {
    const booking = await getBooking(bookingId)
    const data = booking.data()
    if (data != null) {
      this.bookingSelected({ id: booking.id, ...booking.data() })
    } else {
      this.loading = false
    }
  }

  bookingSelected (booking: WithId<Booking>): void {
    this.loading = true
    this.booking = booking
    this.uploadStepper.next()
  }

  photographerSelected (photographer: BookingPhotographer): void {
    this.loading = true
    this.photographer = photographer
    this.uploadStepper.next()
  }

  jobTypeSelected (jobType: string): void {
    this.loading = true
    this.jobType = jobType
    this.uploadStepper.next()
  }

  jobSelected (job: WithId<Job> | null): void {
    this.loading = true
    this.job = job
    this.dataComplete()
  }

  dataComplete (): void {
    labUploadDataProvider.setData(this.booking, this.photographer, this.jobType, this.job)
    this.$emit('input', this.bookingId, this.job?.id)
  }
}
