import { CreateJobResult, JobMetaResult, StudioJob } from '@ht-lib/accounts-models'
import { tryCreateUploadImage } from './UploadImage'

export async function getJobMetaFile (files: File[]): Promise<JobMetaResult | Error> {
  let metaFileIndex = files.findIndex(x => x.name === 'job.json')

  if (metaFileIndex === -1) {
    // Look for job.json files added to a CD with the format `job_1.json` where `1` is the CD number
    metaFileIndex = files.findIndex(x => x.name.startsWith('job') && x.name.endsWith('.json'))
  }

  if (metaFileIndex === -1) {
    return new Error('No job metadata, are you missing job.json?')
  }

  const [metaFile] = files.splice(metaFileIndex, 1)
  const meta = await new Promise<StudioJob>((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => resolve(JSON.parse(reader.result as string))
    reader.readAsText(metaFile)
  })

  return {
    jobJson: metaFile,
    studio: meta
  }
}

export async function tryCreateUploadJob (files: File[]): Promise<CreateJobResult> {
  const result = await getJobMetaFile(files)
  if (result instanceof Error) {
    return {
      errors: [result.message]
    }
  }

  const imageResults = result.studio.job.classes.flatMap(c =>
    c.images.flatMap(image => tryCreateUploadImage(files, { ...image, className: c.name, photographerCode: c.photographerCode }))
  )

  const images = imageResults.map(x => x.image).filter(Boolean)
  const errors = imageResults.map(x => x.error).filter((x) => typeof x === 'string')
  return {
    errors,
    job: {
      images,
      studio: result.studio,
      jobJson: result.jobJson
    }
  }
}
