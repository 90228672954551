
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { byBookingIdUploadSiteQuery } from '@ht-lib/accounts-common'
import { Job, WithId } from '@ht-lib/accounts-models'
import { Prop } from 'vue-property-decorator'
import { utcToZonedTime, format } from 'date-fns-tz'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { },
  name: 'SelectJob'
})
export default class extends Vue {
  @Prop({ type: String, required: true }) bookingId!: string
  @Prop({ type: String, required: true }) jobType!: string
  private jobItems: Array<WithId<Job>> = []

  get hasJobs (): boolean { return this.jobs.length > 0 }

  get jobs (): Array<WithId<Job>> { return this.jobItems }

  async created (): Promise<void> {
    const jobQuery = await byBookingIdUploadSiteQuery(this.bookingId)
      .where('jobType', '==', this.jobType)
      .get()

    if (jobQuery.size === 0) {
      this.$emit('input', null)
    } else {
      this.jobItems = jobQuery.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      this.$emit('loaded')
    }
  }

  jobDate (job: Job): string {
    const zoned = utcToZonedTime(job.takeUnixTimestamp, 'Europe/London')
    return format(zoned, 'dd MMM yyy', { timeZone: 'Europe/London' })
  }

  photographers (job: Job): string[] {
    return Object.values(job.photographersMap).map(p => p.name)
  }
}
