



























import { Component, Vue, Prop } from 'vue-property-decorator'
import AccountBookingList from './AccountBookingList.vue'
import BookingsTabs from './BookingsTabs.vue'
import PhotographerBookingList from './PhotographerBookingList.vue'
import { BookingStoreKind, BookingStoreProvider } from '../bookings'

@Component({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { AccountBookingList, PhotographerBookingList, BookingsTabs, BookingStoreProvider },
  name: 'BookingList'
})
export default class extends Vue {
  @Prop({ required: true, type: String }) userType: BookingStoreKind
  get isAccount (): boolean {
    return this.userType === BookingStoreKind.Account
  }

  get isPhotographer (): boolean {
    return this.userType === BookingStoreKind.Photographer
  }

  get isLabUploader (): boolean {
    return this.userType === BookingStoreKind.LabUploader
  }
}
